<template>
  <!-- Footer Start -->
  <footer class="footer-part footer-gape">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="footer-logo">
            <a href="#"><img src="@/assets/img/logo.png" alt="logo" /></a>
            <ul>
              <li>
                <a class="icon icon-inline" href="#"><i class="fab fa-facebook-f"></i></a>
              </li>
              <li>
                <a class="icon icon-inline" href="#"><i class="fab fa-twitter"></i></a>
              </li>
              <li>
                <a class="icon icon-inline" href="#"><i class="fab fa-linkedin-in"></i></a>
              </li>
              <li>
                <a class="icon icon-inline" href="#"><i class="fab fa-instagram"></i></a>
              </li>
              <li>
                <a class="icon icon-inline" href="#"><i class="fab fa-youtube"></i></a>
              </li>
            </ul>
          </div>
          <form  @submit.prevent="addSubscriber" class="news-form">
            <input type="text" placeholder="Enter email for Newsletter" v-model="email" /><button type="submit" class="btn btn-inline">
              <i class="fas fa-envelope"></i><span>Subscribe</span>
            </button>
            <div v-if="data.status === 'ok'">
              <div v-show="data.status === 'ok'" class="alert alert-success mt-2">{{data.message}}</div>
            </div>
            <div v-else>
              <div v-show="data.status === 'error'" class="alert alert-danger mt-2">{{data.message}}</div>
            </div>
          </form>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4 col-md-6">
          <div class="footer-heading">
            <h3>contact us</h3>
            <p>
              Shine FM is Daystar University’s Radio Station. It was launched in
              2003 and is run entirely by students.
            </p>
          </div>
          <ul class="footer-address">
            <li>
              <i class="fas fa-map-marked-alt"></i>
              <p>Daystar University, Main Campus.</p>
            </li>
            <li>
              <i class="fas fa-envelope-open-text"></i>
              <p>shine@daystar.ac.ke</p>
            </li>
            <li>
              <i class="fas fa-phone"></i>
              <p>+254714693142</p>
            </li>
          </ul>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="footer-heading">
            <h3>important links</h3>
          </div>
          <ul class="footer-link">
            <li>
              <router-link :to="{ name: 'About' }"><i class="fas fa-angle-right"></i>About Shine FM radio</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'Team' }"><i class="fas fa-angle-right"></i>Shine FM Leadership</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'Show' }"><i class="fas fa-angle-right"></i>Show Schedule</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'Blog' }"><i class="fas fa-angle-right"></i>Shine FM Blog</router-link>
            </li>
            <li>
              <a href="https://application.daystar.ac.ke" target="_blank"><i class="fas fa-angle-right"></i>Apply to Daystar</a>
            </li>
          </ul>
        </div>
        <div class="col-lg-4">
          <div class="footer-heading">
            <h3>download our app</h3>
            <p>
              Shine FM app is available for download in Android version.
              Get started now to enjoy exclusive features at your palm.
              Shine FM 99.9, Radiating Lives.
            </p>
          </div>
          <div class="download-part">
            <ul>
              <li>
                <h5>available on -</h5>
              </li>
              <li>
                <a  href="#"><img src="@/assets/img/google.png" alt="google" /></a>
              </li>
              <li>
                <a href="#"><img src="@/assets/img/app.png" alt="app" /></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </footer>
  <section class="copy-part">
    <div class="container">
      <ul>
        <li>
          <h6>
            &copy; Copyright © <span>{{ new Date().getFullYear() }} </span>
          </h6>
        </li>
        <li>
          <h6>All rights reserved by - <span>Shine FM 99.9</span></h6>
        </li>
        <li>
          <h6>
            Owned by -
            <a target="_blank" href="https://daystar.ac.ke">Daystar University</a>
          </h6>
        </li>
      </ul>
    </div>
  </section>
  <!-- Footer End -->
</template>

<script>
export default {
  data() {
    return{
      email: 'example@gmail.com',
      data: {}
    }
  },

  methods: {
    async addSubscriber() {
      let json_data =JSON.stringify({
        "data" : {
            "email": this.email
        }
      });
      const res = await fetch(this.base_url + "api/addEmailSubscriber", {method: 'POST', body: json_data});
      const finalRes = await res.json();
      this.data = finalRes;
      console.log(JSON.stringify(this.data))
    },
  },

};
</script>

<style>

</style>