<template>
  <layoutView>
      <template v-slot:header>
        <Header />
      </template>
      <template v-slot:default>
        <!-- <router-view/> -->
      </template>
      <template v-slot:footer>
        <Footer />
      </template>
  </layoutView>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Partials/HeaderComponent.vue'
import LayoutView from "@/components/Layout/LayoutView.vue"
import Footer from '@/components/Partials/FooterComponent.vue'

export default {
  components: {
    Header,
    LayoutView,
    Footer,
    
  }
}
</script>
<style>
</style>
