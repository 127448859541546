export default{
    data() {
      return {
        base_url: 'https://daystar.ac.ke/shinefm/shinefm-backend/',
        // base_url: 'http://localhost/shinefm-backend/',
      };
    },
    created: function() {
    //   this.greetings();
    },
    methods: {
        truncate(value, length) {
            if (value.length > length) {
                return value.substring(0, length) + "...";
            } else {
                return value;
            }
        }
    }
  };