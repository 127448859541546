import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import myMixin from './myMixin'
import VueDisqus from 'vue-disqus'

const app = createApp(App)
.use(store)
.use(router)
.use(VueDisqus, { shortname: 'https://daystar-university.disqus.com/embed.js' })
app.mixin(myMixin)
.mount('#app')
