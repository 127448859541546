<template>
    <div>
      <header class="header-slot">
        <slot name="header"></slot>
      </header>
      <main class="main-slot">
        <slot></slot>
      </main>
      <footer class="footer-slot">
        <slot name="footer"></slot>
      </footer>
    </div>
  </template>
  
  <script>
  export default {}
  </script>
  
  <style scoped>
 
  </style>