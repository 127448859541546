<template>
    <header class="header-part">
        <div class="header-bg">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6">
                        <router-link class="header-logo" :to="{ name: 'Home' }"><img src="@/assets/img/logo.png"
                                alt="logo" /></router-link>
                    </div>
                    <div class="col-lg-6">
                        <div class="header-btn">
                            <a class="btn btn-outline" href="#"><i class="fas fa-download"></i><span>Download App</span></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
    <nav class="navbar-part">
        <div class="navbar-bg">
            <div class="container">
                <div class="navbar-content">
                    <router-link class="navbar-logo" :to="{ name: 'Home' }"><img src="@/assets/img/logo.png"
                            alt="logo" /></router-link><button class="navbar-toggle" type="button">
                        <i class="fas fa-bars"></i>
                    </button>
                    <div class="navbar-overlay">
                        <div class="navbar-slide">
                            <div class="slide-head">
                                <router-link :to="{ name: 'Home' }"><img src="@/assets/img/logo.png" alt="logo" />
                                </router-link><button class="cencel"><i class="fas fa-times"></i></button>
                            </div>
                            <ul class="navbar-list">
                                <li class="navbar-item" :class="[$route.name === 'Home' ? 'active' : '']">
                                    <router-link class="navbar-link" :to="{ name: 'Home' }"><span><i
                                                class="fas fa-headphones-alt"></i>Home</span></router-link>
                                </li>
                                <li class="navbar-item" :class="[$route.name === 'About' ? 'active' : '']">
                                    <router-link class="navbar-link" :to="{ name: 'About' }"><span><i
                                                class="fas fa-headphones-alt"></i>About us</span></router-link>
                                </li>
                                <li class="navbar-item" :class="[$route.name === 'Show' ? 'active' : '']">
                                    <router-link class="navbar-link" :to="{ name: 'Show' }"><span><i
                                                class="fas fa-headphones-alt"></i>Show Schedule</span></router-link>
                                </li>
                                <li class="navbar-player">
                                    <div class="player">
                                        <button class="player-btn" id="play-pause-button">
                                            <i class="fas fa-play"></i>
                                        </button>
                                    </div>
                                </li>
                                <li class="navbar-item" :class="[$route.name === 'Team' ? 'active' : '']">
                                    <router-link class="navbar-link" :to="{ name: 'Team' }"><span><i
                                                class="fas fa-user"></i>Team</span></router-link>
                                </li>
                                <li class="navbar-item" :class="[$route.name === 'Blog' ? 'active' : '']">
                                    <router-link class="navbar-link" :to="{ name: 'Blog' }"><span><i
                                                class="fas fa-comment"></i>Blog</span></router-link>
                                </li>
                                <!-- <li class="navbar-item navbar-dropdown">
                                    <a class="navbar-link" href="#"><span><i
                                                class="fas fa-headphones-alt"></i>pages</span><small
                                            class="fas fa-chevron-down"></small></a>
                                    <ul class="dropdown-list">
                                        <li>
                                            <a class="dropdown-link" href="team.html">Team Members</a>
                                        </li>
                                        <li>
                                            <a class="dropdown-link" href="video.html">Videos</a>
                                        </li>
                                        <li>
                                            <a class="dropdown-link" href="gallery.html">Gallery</a>
                                        </li>
                                        <li>
                                            <a class="dropdown-link" href="sponsor.html">Become a Sponsor</a>
                                        </li>
                                    </ul>
                                </li> -->
                                <li class="navbar-item" :class="[$route.name === 'Contact' ? 'active' : '']">
                                    <router-link class="navbar-link" :to="{ name: 'Contact' }"><span><i
                                                class="fas fa-send"></i>Contact</span></router-link>
                                </li>
                            </ul>
                            <div class="navbar-btn">
                                <a class="btn btn-outline" href="#"><i
                                        class="fas fa-download"></i><span>Download App</span></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </nav>
    <div class="bottom-player">
        <div class="player">
            <button class="player-btn" id="play-pause-button2">
                <i class="fas fa-play"></i>
            </button>
        </div>
    </div>
    <!-- Navbar End -->
    <router-view />
</template>

<script>
export default {
    //     props: {
    //     title: {
    //       type: String,
    //       default: "",
    //     },
    //     items: {
    //       type: Array,
    //       default: () => {
    //         return [];
    //       },
    //     },
    //   },
    // data() {
    //     return {
    //       title: null,
    //       currentpage: null
    //     }
    //   },
    //   created(){
    //    this.title = this.$route.meta.title;
    //    this.currentpage = this.$route.name;
    //   }

    mounted: function () {
        //
    },
    methods: {
        myClickHandler() {
            let panel = this.$refs.myPanel
            //
        }
    }
};
</script>

<style>
.player {
    background: url(@/assets/img/player-bg.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 250px;
    height: 250px;
    border-radius: 50%;
    border: 15px solid var(--white);
}

.header-bg {
    background: url(@/assets/img/assets-bg.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: 25px 0px 0px;
}

.navbar-bg {
    background: url(@/assets/img/assets-bg.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
</style>