import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/pages/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: {
      title: "Home"
    },
    component: HomeView
  },
  {
    path: '/about',
    name: 'About',
    meta: {
      title: "About"
    },
    component: () => import('../views/pages/AboutView.vue'),
  },
  {
    path: '/show',
    name: 'Show',
    meta: {
      title: "Show"
    },
    component: () => import('../views/pages/ShowView.vue'),
  },
  {
    path: '/blog',
    name: 'Blog',
    meta: {
      title: "Blog"
    },
    component: () => import('../views/pages/BlogListView.vue'),
  },
  {
    path: '/blog/:slug',
    name: 'BlogDetails',
    meta: {
      title: "Blog Details"
    },
    component: () => import('../views/pages/BlogDetailsView.vue'),
  },
  {
    path: '/blog/category/:category_id',
    name: 'BlogCat',
    meta: {
      title: "Post Categories"
    },
    component: () => import('../views/pages/BlogListView.vue'),
  },
  {
    path: '/team',
    name: 'Team',
    meta: {
      title: "Team"
    },
    component: () => import('../views/pages/TeamView.vue'),
  },
  {
    path: '/contact',
    name: 'Contact',
    meta: {
      title: "Contact"
    },
    component: () => import('../views/pages/ContactView.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    meta: {
      title: "Page Not Found"
    },
    component: () => import('../views/pages/NotFoundView.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  mode: 'history',
  base: 'shinefm',
  routes
});

const DEFAULT_TITLE = 'Shine FM';
router.beforeEach((to) => {
  document.title = to.meta.title + ' | Shine FM - Radiating Lives' || DEFAULT_TITLE;
  window.scrollTo(0, 0)
});


export default router
