<template>
  <main class="banner-slider">
    <section class="banner-1">
      <div class="banner-oly">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="banner-content">
                <h1>SHINE FM 99.9</h1>
                <p>Radiating Lives</p>
                <router-link :to="{ name: 'About' }" class="btn btn-inline"><span>About us <i class="fas fa-arrow-right"></i></span></router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="banner-2">
      <div class="banner-oly">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="banner-content">
                <h1>Stay up to date</h1>
                <p>The Home of Urban & Classic Christian Hits</p>
                <router-link :to="{ name: 'Blog' }" class="btn btn-inline"><span>Our Blog <i class="fas fa-arrow-right"></i></span></router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="banner-3">
      <div class="banner-oly">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="banner-content">
                <h1>Our Vision</h1>
                <p>Lives transformed through Information, Education and Spiritual Nurture</p>
                <router-link :to="{ name: 'About' }" class="btn btn-inline"><i class="fas fa-info"></i><span>About Shine FM</span></router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
  <section class="section about-part">
    <div class="container">
      <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-6">
          <div class="about-img">
            <a href="#" class="about-1"><img src="@/assets/img/about-1.jpg" alt="about-1" /></a><a href="#"
              class="about-2"><img src="@/assets/img/about-2.jpg" alt="about-2" /></a>
          </div>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-6">
          <div class="about-content">
            <h2><span>SHINE FM</span>about</h2>
            <p>
              Shine FM is Daystar University’s Radio Station. It was launched in 2003 and is run entirely by students.
              The aim of the station is to provide a platform for Communication Major students to transform their
              academic knowledge into practice, thus sharpening their skills in all aspects that pertain to the
              broadcast industry.
            </p>
            <p>
              <strong>Vision:</strong> Lives transformed through Information, Education and Spiritual Nurture..
            </p>
            <h3>Since 2003</h3>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="section show-part">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="section-heading">
            <h2>show Schedule</h2>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div id="show-menu">
            <ul class="nav nav-tabs">
              <li>
                <a href="#sat" :class="[today === 'Saturday' ? 'nav-link active' : 'nav-link']" data-toggle="tab">Saturday</a>
              </li>
              <li>
                <a href="#sun" :class="[today === 'Sunday' ? 'nav-link active' : 'nav-link']" data-toggle="tab">sunday</a>
              </li>
              <li>
                <a href="#mon" :class="[today === 'Monday' ? 'nav-link active' : 'nav-link']" data-toggle="tab">monday</a>
              </li>
              <li>
                <a href="#tue" :class="[today === 'Tuesday' ? 'nav-link active' : 'nav-link']" data-toggle="tab">Tuesday</a>
              </li>
              <li>
                <a href="#wed" :class="[today === 'Wednesday' ? 'nav-link active' : 'nav-link']" data-toggle="tab">Wednesday</a>
              </li>
              <li>
                <a href="#thu" :class="[today === 'Thursday' ? 'nav-link active' : 'nav-link']" data-toggle="tab">Thursday</a>
              </li>
              <li>
                <a href="#fri" :class="[today === 'Friday' ? 'nav-link active' : 'nav-link']" data-toggle="tab">Friday</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div :class="[today === 'Saturday' ? 'tab-pane active' : 'tab-pane']" id="sat">
            <div class="row">
              <div class="col-sm-6 col-md-4 col-lg-3" v-for="schedule in filteredSaturday.slice(0,8)" :key="schedule.id">
                <div class="show-card">
                  <div class="show-content">
                    <div class="show-bg">
                      <img :src="schedule.thumbnail" :alt="schedule.title" />
                    </div>
                    <div class="show-overlay">
                      <div class="show-time">
                        <p>{{schedule.time}}</p>
                        <h4>{{schedule.title}}</h4>
                      </div>
                      <ul class="show-host">
                        <li>
                          <a href="#"><img src="@/assets/img/host-1.jpg" alt="host-1" /></a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="show-meta">
                    <h5>{{schedule.presenter}}</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div :class="[today === 'Sunday' ? 'tab-pane active' : 'tab-pane']" id="sun">
            <div class="row">
              <div class="col-sm-6 col-md-4 col-lg-3" v-for="schedule in filteredSunday.slice(0,8)" :key="schedule.id">
                <div class="show-card">
                  <div class="show-content">
                    <div class="show-bg">
                      <img :src="schedule.thumbnail" :alt="schedule.title" />
                    </div>
                    <div class="show-overlay">
                      <div class="show-time">
                        <p>{{schedule.time}}</p>
                        <h4>{{schedule.title}}</h4>
                      </div>
                      <ul class="show-host">
                        <li>
                          <a href="#"><img src="@/assets/img/host-1.jpg" alt="host-1" /></a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="show-meta">
                    <h5>{{schedule.presenter}}</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div :class="[today === 'Monday' ? 'tab-pane active' : 'tab-pane']" id="mon">
            <div class="row">
              <div class="col-sm-6 col-md-4 col-lg-3" v-for="schedule in filteredMonday.slice(0,8)" :key="schedule.id">
                <div class="show-card">
                  <div class="show-content">
                    <div class="show-bg">
                      <img :src="schedule.thumbnail" :alt="schedule.title" />
                    </div>
                    <div class="show-overlay">
                      <div class="show-time">
                        <p>{{schedule.time}}</p>
                        <h4>{{schedule.title}}</h4>
                      </div>
                      <ul class="show-host">
                        <li>
                          <a href="#"><img src="@/assets/img/host-1.jpg" alt="host-1" /></a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="show-meta">
                    <h5>{{schedule.presenter}}</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div :class="[today === 'Tuesday' ? 'tab-pane active' : 'tab-pane']" id="tue">
            <div class="row">
              <div class="col-sm-6 col-md-4 col-lg-3" v-for="schedule in filteredTuesday.slice(0,8)" :key="schedule.id">
                <div class="show-card">
                  <div class="show-content">
                    <div class="show-bg">
                      <img :src="schedule.thumbnail" :alt="schedule.title" />
                    </div>
                    <div class="show-overlay">
                      <div class="show-time">
                        <p>{{schedule.time}}</p>
                        <h4>{{schedule.title}}</h4>
                      </div>
                      <ul class="show-host">
                        <li>
                          <a href="#"><img src="@/assets/img/host-1.jpg" alt="host-1" /></a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="show-meta">
                    <h5>{{schedule.presenter}}</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div :class="[today === 'Wednesday' ? 'tab-pane active' : 'tab-pane']" id="wed">
            <div class="row">
              <div class="col-sm-6 col-md-4 col-lg-3" v-for="schedule in filteredWednesday.slice(0,8)" :key="schedule.id">
                <div class="show-card">
                  <div class="show-content">
                    <div class="show-bg">
                      <img :src="schedule.thumbnail" :alt="schedule.title" />
                    </div>
                    <div class="show-overlay">
                      <div class="show-time">
                        <p>{{schedule.time}}</p>
                        <h4>{{schedule.title}}</h4>
                      </div>
                      <ul class="show-host">
                        <li>
                          <a href="#"><img src="@/assets/img/host-1.jpg" alt="host-1" /></a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="show-meta">
                    <h5>{{schedule.presenter}}</h5>
                  </div>
                </div>
              </div>
              </div>
            </div>
          </div>
          <div :class="[today === 'Thursday' ? 'tab-pane active' : 'tab-pane']" id="thu">
            <div class="row">
              <div class="col-sm-6 col-md-4 col-lg-3" v-for="schedule in filteredThursday.slice(0,8)" :key="schedule.id">
                <div class="show-card">
                  <div class="show-content">
                    <div class="show-bg">
                      <img :src="schedule.thumbnail" :alt="schedule.title" />
                    </div>
                    <div class="show-overlay">
                      <div class="show-time">
                        <p>{{schedule.time}}</p>
                        <h4>{{schedule.title}}</h4>
                      </div>
                      <ul class="show-host">
                        <li>
                          <a href="#"><img src="@/assets/img/host-1.jpg" alt="host-1" /></a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="show-meta">
                    <h5>{{schedule.presenter}}</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div :class="[today === 'Friday' ? 'tab-pane active' : 'tab-pane']" id="fri">
            <div class="row">
              <div class="col-sm-6 col-md-4 col-lg-3" v-for="schedule in filteredFriday.slice(0,8)" :key="schedule.id">
                <div class="show-card">
                  <div class="show-content">
                    <div class="show-bg">
                      <img :src="schedule.thumbnail" :alt="schedule.title" />
                    </div>
                    <div class="show-overlay">
                      <div class="show-time">
                        <p>{{schedule.time}}</p>
                        <h4>{{schedule.title}}</h4>
                      </div>
                      <ul class="show-host">
                        <li>
                          <a href="#"><img src="@/assets/img/host-1.jpg" alt="host-1" /></a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="show-meta">
                    <h5>{{schedule.presenter}}</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="show-btn">
            <a class="btn btn-outline" href="show.html"><i class="fas fa-eye"></i><span>show more</span></a>
          </div>
        </div>
      </div>
  </section>

  <section class="section blog-part mb-5">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="section-heading">
            <h2>recent episodes</h2>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 col-md-4 col-lg-4 mb-5" v-for="audio in audios" :key ="audio.id">
          <div class="blog-card">
            <img :src="audio.cover_photo" :alt="audio.title" :title="audio.title" />
            <div class="blog-overlay">
              <!-- <p>{{ post.created_at }}</p> -->
              <h3 class="text-white text-center">
                {{ audio.title }}
              </h3>
              <audio id="player2" style="bottom: 20px; position: absolute;" :src="audio.source" controls></audio>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="team-part mt-0">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="section-heading">
              <h2>Our Team</h2>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6 col-sm-6 col-md-4 col-lg-3" v-for="leader in leaders" :key ="leader.id">
            <div class="team-card team-gape">
              <div class="team-img">
                <img :src="this.base_url + leader.thumbnail" :alt="leader.name" :title="leader.name" />
                <div class="team-overlay">
                  <ul class="team-icon">
                    <li>
                      <a class="icon icon-inline" href="#"
                        ><i class="fab fa-facebook-f"></i
                      ></a>
                    </li>
                    <li>
                      <a class="icon icon-inline" href="#"
                        ><i class="fab fa-twitter"></i
                      ></a>
                    </li>
                    <li>
                      <a class="icon icon-inline" href="#"
                        ><i class="fab fa-linkedin-in"></i
                      ></a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="team-meta">
                <h4>{{ leader.name }}</h4>
                <p>{{ leader.position }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <div class="team-btn">
              <router-link class="btn btn-outline" :to="{ name:'Team' }"><a class="fas fa-eye"></a><span>show more</span></router-link>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="counter-part">
      <div class="counter-oly">
        <div class="container">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12">
              <div class="counter-div">
                <h3><span class="counter-number">Shine 99.9</span> fm</h3>
                <h4 class="text-white">The Home of Urban & Classic Christian Hits</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  <section class="section blog-part">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="section-heading">
            <h2>recent articles</h2>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 col-md-4 col-lg-4 mb-5" v-for="post in posts" :key ="post.id">
          <div class="blog-card">
            <img :src="this.base_url + post.thumbnail" alt="{{ post.title }}" title="{{ post.title }}" />
            <div class="blog-overlay">
              <p>{{ post.created_at }}</p>
              <h3>
                <router-link :to="{name:'BlogDetails', params:{slug:post.slug} }">{{ post.title }}</router-link>
              </h3>
                <router-link class="btn btn-outline" :to="{name:'BlogDetails', params:{slug:post.slug} }">read more</router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="blog-btn">
            <router-link class="btn btn-outline" :to="{name:'Blog'}"><i class="fas fa-eye"></i><span>Show More</span></router-link>
          </div>
        </div>
      </div>
    </div>
  </section>

</template>

<script>
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'

export default {
  components: {},

  name: 'HomeView',

  data(){
    return{
      title: null,
      currentpage: null,
      today: null,
      blog_data: [],
      leaders_data: [],
      audio_data: [],
      schedule_data: [],
    }
  },

  methods: {
    async getPosts() {
      const res = await fetch(this.base_url + "fetch_blog_posts");
      const finalRes = await res.json();
      this.blog_data = finalRes.blog_posts
      // console.log(JSON.stringify(this.blog_data))
    },
    async getLeaders() {
      const res = await fetch(this.base_url + "fetch_leaders");
      const finalRes = await res.json();
      this.leaders_data = finalRes.leaders;
      // console.log(JSON.stringify(this.leaders_data))
    },
    async getAudios() {
      let json_data =JSON.stringify({
        "data" : {
            "media_type": "audio"
        }
      });
      const res = await fetch(this.base_url + "fetch_media", {method: 'POST', body: json_data});
      const finalRes = await res.json();
      this.audio_data = finalRes.media;
      console.log(JSON.stringify(this.audio_data))
    },
    async getSchedule() {
      const res = await fetch(this.base_url + "fetch_schedule");
      const finalRes = await res.json();
      this.schedule_data = finalRes.schedule;
      // console.log(JSON.stringify(this.schedule_data))
    },
    getDayName(){
      const dayIndex = new Date().getDay();
      const getDayName = (dayIndex) =>{
        const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        return days[dayIndex];
      }
      const dayName = getDayName(dayIndex)
      this.today = dayName
      // console.log(dayName)
    }
  },
  created() {
    this.title = this.$route.meta.title;
    this.currentpage = this.$route.name;
    this.getPosts()
    this.getLeaders()
    this.getAudios()
    this.getDayName()
    this.getSchedule()
  },

  computed: {
   leaders () {
     return this.leaders_data.slice(0,4)
   },
   posts () {
     return this.blog_data.slice(0,3)
   },
   audios(){
    return this.audio_data.slice(0,3)
   },
  filteredMonday: function() {
    return this.schedule_data.filter((item) => {
      return (item.day ==='Monday')
    });
  },
  filteredTuesday: function() {
    return this.schedule_data.filter((item) => {
      return (item.day ==='Tuesday')
    });
  },
  filteredWednesday: function() {
    return this.schedule_data.filter((item) => {
      return (item.day ==='Wednesday')
    });
  },
  filteredThursday: function() {
    return this.schedule_data.filter((item) => {
      return (item.day ==='Thursday')
    });
  },
  filteredFriday: function() {
    return this.schedule_data.filter((item) => {
      return (item.day ==='Friday')
    });
  },
  filteredSaturday: function() {
    return this.schedule_data.filter((item) => {
      return (item.day ==='Saturday')
    });
  },
  filteredSunday: function() {
    return this.schedule_data.filter((item) => {
      return (item.day ==='Sunday')
    });
  }
},

  // mounted() {
  //   this.getPosts()
  // },

  beforeMount(){
    // this.getPosts()
    window.slickInit()
  },

  beforeRouteEnter(to, from, next) {
    next(() => {
      window.slickInit()
    })
  }
}
</script>
<style scoped> 
    .carousel__prev, .carousel__next {
      position: absolute;
      top: 50%;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      font-size: 16px;
      width: 48px;
      height: 48px;
      line-height: 48px;
      text-align: center;
      border-radius: 50%;
      color: var(--white);
      background: var(--primary-gdnt);
      text-shadow: 2px 3px 8px rgba(0, 0, 0, 0.1);
      -webkit-box-shadow: 0px 10px 25px 0px rgba(0, 0, 0, 0.15);
      box-shadow: 0px 10px 25px 0px rgba(0, 0, 0, 0.15);
      cursor: pointer;
      /* visibility: hidden; */
      /* opacity: 0; */
      z-index: 1;
      transition: all linear 0.3s;
      -webkit-transition: all linear 0.3s;
      -moz-transition: all linear 0.3s;
      -ms-transition: all linear 0.3s;
      -o-transition: all linear 0.3s;
    }

    .carousel__icon{
      color: #fff;
    }

    .video-card{
      margin: 10px 15px;
    }

</style>
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.nav {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.nav-tabs li {
  width: 100%;
}

.nav-tabs li .nav-link {
  width: 100%;
  border: none;
  padding: 10px 0px;
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  color: var(--text);
  border-bottom: 3px solid transparent;
  text-shadow: 2px 3px 8px rgba(0, 0, 0, 0.1);
}

.nav-tabs li .nav-link:hover {
  border-color: transparent;
}

.nav-tabs li .active {
  color: var(--white) !important;
  background: var(--primary-gdnt);
  border-color: var(--primary) !important;
}

.tab-pane {
  padding: 30px 0px 0px;
  display: none;
}

.tab-pane.active {
  display: block;
}

.dandik,
.bamdik {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 16px;
  width: 48px;
  height: 48px;
  line-height: 48px;
  text-align: center;
  border-radius: 50%;
  color: var(--white);
  background: var(--primary-gdnt);
  text-shadow: 2px 3px 8px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0px 10px 25px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 10px 25px 0px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  visibility: hidden;
  opacity: 0;
  z-index: 1;
  transition: all linear 0.3s;
  -webkit-transition: all linear 0.3s;
  -moz-transition: all linear 0.3s;
  -ms-transition: all linear 0.3s;
  -o-transition: all linear 0.3s;
}

.dandik:hover,
.bamdik:hover {
  color: var(--white);
  background: var(--hover-gdnt);
}

.dandik {
  right: -15px;
}

.bamdik {
  left: -15px;
}

.slider-arrow:hover .dandik,
.slider-arrow:hover .bamdik, .slider-carouse:hover, .carousel__prev:hover, .carousel__next:hover{
  visibility: visible;
  opacity: 1;
}

@media (min-width: 576px) and (max-width: 1199px) {
  .dandik {
    right: 0px;
  }

  .bamdik {
    left: 0px;
  }
}

.show-card {
  margin-bottom: 30px;
}

.show-card:hover .show-overlay {
  background: var(--show-hover-oly);
}

.show-card:hover .show-bg img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.show-content {
  position: relative;
  margin-bottom: 38px;
}

.show-bg {
  overflow: hidden;
  border-radius: 10px;
}

.show-bg img {
  width: 100%;
  border-radius: 10px;
  transition: all linear 0.3s;
  -webkit-transition: all linear 0.3s;
  -moz-transition: all linear 0.3s;
  -ms-transition: all linear 0.3s;
  -o-transition: all linear 0.3s;
}

.show-overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background: var(--show-oly);
}

.show-active {
  position: absolute;
  top: -10px;
  left: -10px;
  width: 45px;
  height: 45px;
  padding: 5px;
  border-radius: 50%;
  overflow: hidden;
  background: var(--white);
}

.show-active img {
  width: 100%;
}

.show-time {
  position: absolute;
  top: 45%;
  left: 50%;
  width: 100%;
  text-align: center;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.show-time p {
  color: var(--white);
  font-size: 20px;
  margin-bottom: 7px;
  font-family: var(--sfamily);
}

.show-time h4 {
  color: var(--white);
  font-weight: var(--mweight);
}

.show-host {
  position: absolute;
  bottom: -30px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.show-host li:nth-child(2),
.show-host li:nth-child(3) {
  margin-left: -25px;
}

.show-host li a img {
  width: 60px;
  border-radius: 50%;
  border: 5px solid var(--white);
}

.show-meta {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.team-card:hover .team-overlay {
  opacity: 1;
  height: 100%;
  visibility: visible;
  background: var(--team-hover-oly);
}

.team-card:hover .team-icon {
  bottom: 30px;
}

.team-img {
  position: relative;
  margin-bottom: 15px;
}

.team-img img {
  width: 100%;
  border-radius: 10px;
}

.team-overlay {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 0%;
  border-radius: 10px;
  visibility: hidden;
  opacity: 0;
  transition: all linear 0.3s;
  -webkit-transition: all linear 0.3s;
  -moz-transition: all linear 0.3s;
  -ms-transition: all linear 0.3s;
  -o-transition: all linear 0.3s;
}

.team-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: absolute;
  bottom: 0px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  transition: all linear 0.3s;
  -webkit-transition: all linear 0.3s;
  -moz-transition: all linear 0.3s;
  -ms-transition: all linear 0.3s;
  -o-transition: all linear 0.3s;
}

.team-icon li {
  margin: 0px 5px;
}

.team-icon .icon i {
  width: 45px;
  height: 45px;
  line-height: 45px;
}

.team-meta {
  text-align: center;
}

.team-meta h4 {
  font-weight: 600;
  font-size: 20px;
}

.video-img {
  position: relative;
  margin-bottom: 15px;
}

.video-img img {
  width: 100%;
  border-radius: 10px;
}

.video-overlay {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background: var(--video-oly);
}

.video-overlay .icon {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.video-overlay .icon i {
  font-size: 14px;
}

.video-overlay .icon-inline i {
  background: var(--video-icon);
}

.video-content {
  padding: 0px 10px;
}

.video-content h5 {
  margin-bottom: 5px;
}

.video-content p {
  font-size: 15px;
  line-height: 24px;
}

.blog-card {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
}
.blog-card:hover img {
  -webkit-transform: scale(1.08);
  transform: scale(1.08);
}
.blog-card:hover .blog-overlay {
  background: var(--blog-hover-oly);
}
.blog-card:hover .blog-overlay .btn-outline {
  background: var(--white);
  border-color: var(--white);
  color: var(--primary);
}
.blog-card img {
  width: 100%;
  border-radius: 10px;
  transition: all linear 0.3s;
  -webkit-transition: all linear 0.3s;
  -moz-transition: all linear 0.3s;
  -ms-transition: all linear 0.3s;
  -o-transition: all linear 0.3s;
}
.blog-overlay {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  padding: 30px;
  background: var(--blog-oly);
}
.blog-overlay p {
  color: var(--white);
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 200px;
}
.blog-overlay h3 {
  position: absolute;
  bottom: 100px;
  left: 25px;
  right: 25px;
}
.blog-overlay h3 a {
  color: var(--white);
}
.blog-overlay .btn {
  font-size: 16px;
  font-weight: 500;
  padding: 8px 25px;
  text-transform: capitalize;
  position: absolute;
  bottom: 30px;
  left: 25px;
}
.blog-overlay .btn-outline {
  color: var(--white);
  border-color: var(--primary);
}

.banner-1 {
  background: url(../../assets/img/banner-1.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  /* border-radius: 10px; */
  overflow: hidden;
}

.banner-2 {
  background: url(../../assets/img/banner-2.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  /* border-radius: 10px; */
  overflow: hidden;
}

.banner-3 {
  background: url(../../assets/img/banner-3.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  /* border-radius: 10px; */
  overflow: hidden;
}

.banner-oly {
  padding: 180px 0px 100px;
  background: var(--banner-oly);
}

.banner-content {
  text-align: center;
}

.banner-content h1 {
  margin-bottom: 12px;
}

.banner-content p {
  color: var(--white);
  font-size: 18px;
  margin-bottom: 45px;
}

@media (max-width: 991px) {
  .banner-content h1 {
    font-size: 42px;
    line-height: 46px;
  }
}

@media (max-width: 767px) {
  .banner-oly {
    padding: 60px 0px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .banner-oly {
    padding: 80px 0px;
  }
}

.about-img {
  position: relative;
}

.about-1 img {
  width: 75%;
  border-radius: 50%;
  -webkit-box-shadow: 0px 0px 30px 20px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 30px 20px rgba(0, 0, 0, 0.1);
}

.about-2 {
  position: absolute;
  bottom: 0px;
  right: 40px;
  border-radius: 50%;
  border: 10px solid var(--white);
  -webkit-box-shadow: 0px 0px 30px 20px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 30px 20px rgba(0, 0, 0, 0.1);
}

.about-2::before {
  position: absolute;
  content: "";
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: -webkit-gradient(linear,
      left top,
      left bottom,
      from(rgba(91, 68, 114, 0.2)),
      to(rgba(203, 48, 255, 0.3)));
  background: linear-gradient(rgba(91, 68, 114, 0.2), rgba(203, 48, 255, 0.3));
}

.about-2 img {
  width: 260px;
  height: 260px;
  border-radius: 50%;
}

.about-content {
  margin-left: 50px;
}

.about-content h2 {
  margin-bottom: 30px;
}

.about-content h2 span {
  font-size: 60px;
  line-height: 68px;
  text-transform: uppercase;
  background: var(--primary-gdnt);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.about-content p {
  margin-bottom: 25px;
}

.about-content h3 {
  position: relative;
  margin-left: 50px;
}

.about-content h3::before {
  position: absolute;
  content: "";
  top: 50%;
  left: -50px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 30px;
  height: 3px;
  border-radius: 30px;
  background: var(--primary-gdnt);
}

@media (max-width: 1199px) {
  .about-2 {
    right: 0px;
  }
}

@media (max-width: 575px) {
  .about-img {
    width: 290px;
    margin: 0 auto 30px;
  }

  .about-1 img {
    width: 80%;
  }

  .about-2 {
    border: 6px solid var(--white);
  }

  .about-2 img {
    width: 150px;
    height: 150px;
  }

  .about-content {
    margin-left: 0px;
  }

  .about-content h2 {
    font-size: 28px;
    line-height: 36px;
    margin-bottom: 20px;
  }

  .about-content h2 span {
    font-size: 40px;
    line-height: 48px;
  }

  .about-content h3 {
    display: inline-block;
  }
}

@media (min-width: 576px) and (max-width: 991px) {
  .about-img {
    width: 500px;
    margin: 0 auto 30px;
  }

  .about-content {
    margin-left: 0px;
  }
}

.show-btn {
  text-align: center;
  margin-top: 20px;
}

@media (max-width: 991px) {
  #show-menu {
    overflow-x: scroll;
  }

  .nav {
    width: 800px;
  }

  .nav-tabs li .nav-link {
    padding: 8px 0px;
  }

  #show-menu::-webkit-scrollbar {
    width: 12px;
    background-color: var(--white);
  }

  #show-menu::-webkit-scrollbar-thumb {
    border-radius: 30px;
    background: #aaaaaa;
    border: 5px solid var(--light-white);
  }
}

@media (min-width: 351px) and (max-width: 575px) {
  .show-part .container {
    max-width: 350px;
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .show-host li a img {
    width: 55px;
  }
}

.team-slider .team-card {
  margin: 0px 15px;
}

.team-slider .dandik,
.team-slider .bamdik {
  top: 38%;
}

.team-btn {
  text-align: center;
  margin-top: 50px;
}

@media (max-width: 767px) {
  .team-slider .team-card {
    width: 240px;
    margin: 0px 10px;
  }
}

.video-slider .video-card {
  margin: 0px 15px;
}

.video-slider .dandik {
  top: 33%;
}

.video-slider .bamdik {
  top: 33%;
}

.video-btn {
  text-align: center;
  margin-top: 50px;
}

@media (max-width: 575px) {
  .video-slider .video-card {
    width: 250px;
    margin: 0px 10px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .video-slider .video-card {
    width: 300px;
    margin: 0px 10px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .video-slider .video-card {
    width: 380px;
    margin: 0px 10px;
  }
}

.gallery-content {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 100%;
  grid-template-columns: 100%;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.gallery-list {
  display: -ms-grid;
  display: grid;
  grid-gap: 25px;
  -ms-grid-columns: (minmax(150px, 1fr)) [auto-fit];
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  -ms-grid-rows: 200px;
  grid-template-rows: 200px;
  grid-auto-flow: dense;
}

.gallery-list li {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
}

.gallery-list li:hover img {
  -webkit-transform: scale(1.08);
  transform: scale(1.08);
}

.gallery-list li::before {
  position: absolute;
  content: "";
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 1;
  border-radius: 10px;
  background: -webkit-gradient(linear,
      left top,
      left bottom,
      from(rgba(91, 68, 114, 0.2)),
      to(rgba(203, 48, 255, 0.3)));
  background: linear-gradient(rgba(91, 68, 114, 0.2), rgba(203, 48, 255, 0.3));
}

.gallery-list li img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  transition: all linear 0.3s;
  -webkit-transition: all linear 0.3s;
  -moz-transition: all linear 0.3s;
  -ms-transition: all linear 0.3s;
  -o-transition: all linear 0.3s;
}

.verti-img {
  grid-row: span 2;
}

.hori-img {
  grid-column: span 2;
}

.big-img {
  grid-row: span 2;
  grid-column: span 2;
}

.gallery-btn {
  text-align: center;
  margin-top: 50px;
}

@media (max-width: 575px) {
  .gallery-list {
    grid-gap: 15px;
  }
}

.blog-slider .blog-card {
  margin: 0px 15px;
}

.blog-slider .dandik {
  top: 50%;
}

.blog-slider .bamdik {
  top: 50%;
}

.blog-btn {
  text-align: center;
  margin-top: 50px;
}

@media (max-width: 575px) {
  .blog-slider .blog-card {
    width: 250px;
    margin: 0px 10px;
  }

  .blog-overlay h4 {
    left: 25px;
    right: 25px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .blog-slider .blog-card {
    width: 325px;
  }
}
audio{
background-color: red;
}

</style>
